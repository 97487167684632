<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-autocomplete
          @focus.once="getVendors"
          v-model="filter.vendor"
          outlined
          label="Fornecedor"
          :items="vendor.items"
          item-text="company_name"
          item-value="id"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="vendor.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-center text-h6 grey--text"
            >
              Nenhum fornecedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de atualização </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    vendor: {
      items: [],
      loading: true,
    },
  }),
  methods: {
    getVendors() {
      this.$api
        .get('vendors')
        .then((res) => {
          this.vendor.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.vendor.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
