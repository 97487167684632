<template>
  <v-chip
    :color="chipColor"
    :outlined="chipDashed"
    class="text-overline white--text"
    :class="{ 'border-dashed': chipDashed }"
  >
    {{ chipLabel }}
  </v-chip>
</template>

<script>
export default {
  props: {
    currentStatus: {
      type: [Number, Boolean],
      default: () => true,
    },
    status: {
      type: Array,
      default: () => [
        { value: true, color: 'success', text: 'Ativo' },
        { value: false, color: 'error', text: 'Inativo' },
      ],
    },
  },
  computed: {
    chipColor() {
      let { color } = this.status.find((el) => this.currentStatus == el.value)
      return color
    },
    chipLabel() {
      let { text } = this.status.find((el) => this.currentStatus == el.value)
      return text
    },
    chipDashed() {
      let { dashed } = this.status.find((el) => this.currentStatus == el.value)
      return dashed
    },
  },
}
</script>

<style scoped>
.border-dashed {
  border-style: dashed;
}
</style>
