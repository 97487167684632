<template>
  <v-row class="mb-2">
    <v-col>
      <h1 class="text-h5 primary--text">
        <v-icon color="primary">fas fa-filter</v-icon>
        Filtro
      </h1>
    </v-col>
    <v-col
      class="d-flex justify-end"
      cols="8"
    >
      <v-btn
        @click="clear"
        class="mx-2"
        color="primary"
        outlined
        rounded
        small
        dark
      >
        Limpar
      </v-btn>
      <v-btn
        @click="filter"
        class="mx-2"
        color="primary"
        rounded
        small
        dark
      >
        Filtrar
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    filter() {
      this.$emit('filter')
    },
    clear() {
      this.$emit('clear')
    },
  },
}
</script>
