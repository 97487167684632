<template>
  <v-navigation-drawer
    @input="toggleDrawer"
    :value="isOpened"
    class="pt-3 pa-5 pr-0 custom-scrollbar"
    width="450px"
    temporary
    right
    fixed
  >
    <component
      @filter="filter"
      @clear="clear"
      :is="currentComponent"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

// PEOPLE FILTERS
import UsersFilter from '@/components/people/users/UsersFilter'
import SellersFilter from '@/components/people/sellers/SellersFilter'
import AgenciesFilter from '@/components/people/agencies/AgenciesFilter'
import CompaniesFilter from '@/components/people/companies/CompaniesFilter'
import ClientsFilter from '@/components/people/clients/ClientsFilter'
import ProfilesFilter from '@/components/people/profiles/ProfilesFilter'
import SearchClientsFilter from '@/components/people/search-clients/SearchClientsFilter'

// STOCk FILTERS
import ProductsFilter from '@/components/stock/products/ProductsFilter'
import CategoriesFilter from '@/components/stock/categories/CategoriesFilter'
import ColorsFilter from '@/components/stock/colors/ColorsFilter'
import CompositionFilter from '@/components/stock/composition/CompositionFilter'
import FactorFilter from '@/components/stock/factor/FactorFilter'
import NcmFilter from '@/components/stock/ncm/NcmFilter'
import PrintFilter from '@/components/stock/print/PrintFilter'
import VendorsFilter from '@/components/stock/vendors/VendorsFilter'

// COMERCIAL FILTERS
import BudgetsFilter from '@/components/comercial/budgets/BudgetsFilter'
import OrdersFilter from '@/components/comercial/orders/OrdersFilter'
import OrderProductsFilter from '@/components/comercial/order-products/OrderProductsFilter'
import CrmFilter from '@/components/comercial/crm/CrmFilter'

export default {
  components: {
    UsersFilter,
    SellersFilter,
    AgenciesFilter,
    CompaniesFilter,
    ClientsFilter,
    ProfilesFilter,
    SearchClientsFilter,
    ProductsFilter,
    CategoriesFilter,
    ColorsFilter,
    CompositionFilter,
    FactorFilter,
    NcmFilter,
    PrintFilter,
    VendorsFilter,
    BudgetsFilter,
    OrdersFilter,
    OrderProductsFilter,
    CrmFilter,
  },
  props: {
    currentComponent: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isOpened: 'getFilterOpened',
    }),
  },
  methods: {
    ...mapMutations(['setFilterDrawer']),

    toggleDrawer(e) {
      this.setFilterDrawer({ isOpened: e })
    },
    filter(filter) {
      let filter_values = Object.values(filter)
      let count = 0

      filter_values.map((val) => {
        if (val !== '' && val !== null) {
          count++
        }
      })

      this.setFilterDrawer({ isOpened: false, filterCount: count })
      this.$emit('filter', filter)
    },
    clear() {
      this.setFilterDrawer({ isOpened: false, filterCount: 0 })
      this.$emit('clear')
    },
  },
}
</script>

<style lang="scss">
.custom-scrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}
</style>
