<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row class="mr-1">
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.order_id"
          outlined
          label="N° do Pedido"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.budget_id"
          outlined
          label="N° do Orçamento"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.status"
          outlined
          label="Status"
          :items="status"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.print_name"
          outlined
          label="Nome para Gravação"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getCompanies"
          v-model="filter.company_id"
          outlined
          label="Empresa"
          :items="company.items"
          :item-text="'company_name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="company.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma empresa encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getSellers"
          v-model="filter.seller_id"
          outlined
          label="Representante | Vendedor"
          :items="seller.items"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="seller.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum Vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          @focus.once="getClients"
          v-model="filter.client_id"
          :items="client.items"
          item-text="trading_name"
          item-value="id"
          label="Cliente"
          rounded
          outlined
          dense
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="client.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum Vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          @focus.once="getContacts"
          v-model="filter.client_contact_id"
          :items="contact.items"
          item-text="name"
          item-value="id"
          label="Contato"
          rounded
          outlined
          dense
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="contact.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum contato encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mr-1">
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de Entrega </span>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.delivery_date_start"
          outlined
          label="De:"
          v-mask="['##/##/####']"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="filter.delivery_date_end"
          outlined
          label="Até:"
          v-mask="['##/##/####']"
          rounded
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row class="mr-1">
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-calendar-alt</v-icon
        >
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
    <v-row class="mr-1">
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de Aprovação </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.approved_date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.approved_date_to"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="mr-1 mt-2">
      <v-col cols="12">
        <v-icon
          color="primary"
          small
          >fas fa-box</v-icon
        >
        <span class="text-body-2"> Produção | Produtos </span>
      </v-col>

      <v-col cols="12">
        <v-autocomplete
          @focus.once="getProducts"
          v-model="filter.product_id"
          outlined
          label="Nome do Produto"
          :items="product.items"
          :item-text="'product_name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="product.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum produto encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.product_reference"
          outlined
          label="Código do Produto"
          rounded
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getPrints"
          v-model="filter.print_id"
          outlined
          label="Gravação"
          :items="print.items"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="print.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma gravação encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getColors"
          v-model="filter.color_id"
          outlined
          label="Cor"
          :items="color.items"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="color.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma cor encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getVendors"
          v-model="filter.vendor_finance_id"
          outlined
          label="Fornecedor Vinculado"
          :items="vendor.items"
          :item-text="'company_name'"
          :item-value="'id'"
          rounded
          dense
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="vendor.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum fornecedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="mr-1 mt-4">
      <v-col
        cols="12"
        md="12"
      >
        <v-icon
          color="primary"
          small
        >
          fas fa-sticky-note
        </v-icon>
        <span class="text-body-2"> Faturamento | Fiscal </span>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-text-field
          v-model="filter.nf_code"
          outlined
          label="N° da Nota Fiscal"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          @focus.once="getOperation"
          v-model="filter.operation_natures"
          outlined
          label="Natureza da Operação"
          :items="operation_nature.items"
          :item-text="'name'"
          :item-value="'id'"
          rounded
          dense
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="operation_nature.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhuma natureza de operação encontrada
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de Emissão </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.emission_date_from"
          label="De"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.emission_date_from"
          label="Até"
          v-mask="['##/##/####']"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    client: {
      items: [],
      loading: true,
    },
    seller: {
      items: [],
      loading: true,
    },
    print: {
      items: [],
      loading: true,
    },
    color: {
      items: [],
      loading: true,
    },
    product: {
      items: [],
      loading: true,
    },
    operation_nature: {
      items: [],
      loading: true,
    },
    vendor: {
      items: [],
      loading: true,
    },
    contact: {
      items: [],
      loading: true,
    },
    company: {
      items: [],
      loading: true,
    },
    status: [
      { id: 1, name: 'Pedido Emitido' },
      { id: 2, name: 'Aprovação de cadastro' },
      { id: 3, name: 'Aprovação de pedido' },
      { id: 4, name: 'Compras' },
      { id: 5, name: 'Pedido Finalizado' },
      { id: 7, name: 'Pedido Cancelado' },
    ],
  }),
  methods: {
    getOperation() {
      this.$api
        .get('operation_natures')
        .then((res) => {
          this.operation_nature.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.operation_nature.loading = false
        })
    },
    getCompanies() {
      this.$api
        .get('companies')
        .then((res) => {
          this.company.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.company.loading = false
        })
    },
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.seller.loading = true
        })
    },
    getClients() {
      this.$api
        .get('people/type/clients')
        .then((res) => {
          this.client.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.client.loading = false
        })
    },
    getContacts() {
      this.$api
        .get('client_contacts/all')
        .then((res) => {
          this.contact.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.contact.loading = false
        })
    },
    getProducts() {
      this.$api
        .get('products/')
        .then((res) => {
          this.product.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finallyy(() => {
          this.product.loading = false
        })
    },
    getPrints() {
      this.$api
        .get('product_prints')
        .then((res) => {
          this.print.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.print.loading = false
        })
    },
    getVendors() {
      this.$api
        .get('vendors/')
        .then((res) => {
          this.vendor.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.vendor.loading = false
        })
    },
    getColors() {
      this.$api
        .get('product_colors/')
        .then((res) => {
          this.color.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.color.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
